import { useEffect, useState } from "react";
import Aside from './manager/Aside';
import Header from './manager/Header'
import Menu from './manager/Menu'
import MenuRecibos from './manager/MenuRecibos'
import MenuPerfil from './manager/MenuPerfil'
import MenuProtocolos from './manager/MenuProtocolos'
import MenuFaqs from './manager/MenuFaqs'
import './Manager.css'

export default function Manager(props) {

  const [plano, setPlano] = useState({});
  const [cat, setCat] = useState('')
	const handlePlano = async () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
		try {
			const res = await fetch(`${origin}/plano`, {
				method: 'GET',
				credentials: 'include',
			})
			const data = await res.json();
			if(data[0]) {
        setPlano(data[0]);
			} else {
				console.log('err');
			}
		} catch (error) {
			console.log(error);
		}
		return
	}

  useEffect(() => {
    handlePlano();
    setCat(localStorage.getItem('cat'));
  }, [])
  useEffect(() => {
    /* console.log(plano); */
  }, [plano])
  
  
  return (
    <>
      <Aside rank={plano} cat={cat} />
      <main>
        <Header />
        {props.menu && <Menu menu info={plano} cat={cat} />}
        {props.recibos && <MenuRecibos />}
        {props.perfil && <MenuPerfil perfil info={plano} cat={cat} />}
        {props.protocolos && <MenuProtocolos />}
        {props.faqs && <MenuFaqs />}
      </main>
    </>
  )
}