import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../Logo'
import Rank from '../SVGS/Rank'
import './Aside.css'

export default function Aside(props) {
  
  const navigate = useNavigate();
  let local = useLocation();
  
  const [open, setOpen] = useState(false);
  const [initName, setInitName] = useState('');
  const [menu, setMenu] = useState('/');
  const [src, setSrc] = useState('');
  
  const openMenu = () => {
    document.body.classList.add('noScroll');
    setOpen(true);
  }
  const closeMenu = () => {
    document.body.classList.remove('noScroll');
    setOpen(false);
  }
  
  const handleOpenMenu = () => {
    if(!open) {
      openMenu();
    }
  }
  const handleCloseMenu = (e) => {
    if(e.target.className === 'open' && open) {
      closeMenu();
    }
  }
  
  const handleLi = (e) => {
    navigate(`${e.currentTarget.dataset.path}`)
    closeMenu();
  }
  
  const handleFoto = async () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    try {
      const res = await fetch(`${origin}/foto`, {
				method: 'GET',
        headers: { 'Content-type': 'application/json' },
				credentials: 'include',
			})
			const data = await res.json();
      if(data) {
        if(data.log === 'null' || data.log === '') {
          setSrc('user-01.jpg');
        } else {
          setSrc('data:image/bmp;base64,'+ data.log)
        }
      }
    } catch (error) {
      console.log(error);
    }
	}
  
  useEffect(() => {
    handleFoto();
    setInitName(localStorage.getItem('initName'));
  }, [])
  useEffect(() => {
    setMenu(local.pathname)
  }, [local])
  useEffect(() => {
    /* console.log(src); */
  }, [src])
  
  
  return (
    <>
    <svg className='menu' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' onClick={handleOpenMenu}>
      <path d='M486,130H26c-14.36,0-26-9-26-20S11.65,90,26,90H486c14.36,0,26,9,26,20S500.35,130,486,130Z M486,276H26c-14.36,0-26-8.95-26-20s11.65-20,26-20H486c14.36,0,26,8.95,26,20S500.35,276,486,276Z M486,422H26c-14.36,0-26-8.95-26-20s11.65-20,26-20H486c14.36,0,26,8.95,26,20S500.35,422,486,422Z' fill='var(--red)'/>
    </svg>
    <aside
      className={open ? 'open' : ''}
      onClick={handleCloseMenu}
    >
      <nav>
        <figure>
          <img className='profile-pic' src={src} alt='' />
          <h4>{initName}</h4>
          {/* RANK */}
          <div className={props.cat !== 'Vigilante' ? 'rank none' : 'rank'}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
              className={props.rank.nivel >= 1 || props.info >= 1 ? '' : 'darken25'}
            >
              <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
            </svg>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
              className={props.rank.nivel >= 2 || props.info >= 2 ? '' : 'darken25'}
            >
              <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
            </svg>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
              className={props.rank.nivel >= 3 || props.info >= 3 ? '' : 'darken25'}
            >
              <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
            </svg>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
              className={props.rank.nivel >= 4 || props.info >= 4 ? '' : 'darken25'}
            >
              <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
            </svg>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
              className={props.rank.nivel >= 5 || props.info >= 5 ? '' : 'darken25'}
            >
              <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
            </svg>
          </div>
        </figure>
        <ul>
          <li
            data-path='/'
            className={menu === '/' ? 'active' : ''}
            onClick={handleLi}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path style={menu === '/' ? {opacity: 1} : {opacity: 0}} className='fill' d='M103.44,492a52.91,52.91,0,0,1-52.83-52.87V261.87L32.33,280a19.07,19.07,0,0,1-26.78,0,18.68,18.68,0,0,1,0-26.57l225.11-223a36.07,36.07,0,0,1,50.68,0l225.11,223a18.68,18.68,0,0,1,0,26.57,19.07,19.07,0,0,1-26.78,0L461.4,261.87V439.13A52.92,52.92,0,0,1,408.56,492Z' />
              <path style={menu === '/' ? {opacity: 0} : {opacity: 1}} d='M103.44,492a52.91,52.91,0,0,1-52.83-52.87V261.87L32.33,280a19.07,19.07,0,0,1-26.78,0,18.68,18.68,0,0,1,0-26.57l225.11-223a36.07,36.07,0,0,1,50.68,0l225.11,223a18.68,18.68,0,0,1,0,26.57,19.07,19.07,0,0,1-26.78,0L461.4,261.87V439.13A52.92,52.92,0,0,1,408.56,492Zm-15-52.87a15,15,0,0,0,15,15H408.56a15,15,0,0,0,15-15V224.34L256,58.32l-167.52,166Z' />
            </svg>
            <p>Início</p>
          </li>
          <li
            data-path='/recibos'
            className={menu === '/recibos' ? 'active' : ''}
            onClick={handleLi}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path style={menu === '/recibos' ? {opacity: 1} : {opacity: 0}} className='fill' d='M438.86,54.86H73.14A73.22,73.22,0,0,0,0,128V384a73.22,73.22,0,0,0,73.14,73.14H438.86A73.22,73.22,0,0,0,512,384V128A73.22,73.22,0,0,0,438.86,54.86ZM108.33,158H291.67a18,18,0,1,1,0,36H108.33a18,18,0,1,1,0-36ZM211.67,354H108.33a18,18,0,1,1,0-36H211.67a18,18,0,1,1,0,36Zm160-80H108.33a18,18,0,1,1,0-36H371.67a18,18,0,1,1,0,36Z' />
              <path style={menu === '/recibos' ? {opacity: 0} : {opacity: 1}} d='M438.86,54.86H73.14A73.22,73.22,0,0,0,0,128V384a73.22,73.22,0,0,0,73.14,73.14H438.86A73.22,73.22,0,0,0,512,384V128A73.22,73.22,0,0,0,438.86,54.86ZM475.43,384a36.61,36.61,0,0,1-36.57,36.57H73.14A36.61,36.61,0,0,1,36.57,384V128A36.61,36.61,0,0,1,73.14,91.43H438.86A36.61,36.61,0,0,1,475.43,128Z M211.67,318H108.33a18,18,0,1,0,0,36H211.67a18,18,0,1,0,0-36Z M291.67,158H108.33a18,18,0,1,0,0,36H291.67a18,18,0,1,0,0-36Z M371.67,238H108.33a18,18,0,1,0,0,36H371.67a18,18,0,1,0,0-36Z' />
            </svg>
            <p>Recibos</p>
          </li>
          <li
            data-path='/protocolos'
            className={menu === '/protocolos' ? 'active' : ''}
            onClick={handleLi}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path d='M493.6,276H162.4c-10.16,0-18.4-8.95-18.4-20s8.24-20,18.4-20H493.6c10.16,0,18.4,8.95,18.4,20S503.76,276,493.6,276Z M493.6,136H162.4c-10.16,0-18.4-9-18.4-20s8.24-20,18.4-20H493.6c10.16,0,18.4,9,18.4,20S503.76,136,493.6,136Z M493.6,416H162.4c-10.16,0-18.4-8.95-18.4-20s8.24-20,18.4-20H493.6c10.16,0,18.4,8.95,18.4,20S503.76,416,493.6,416Z' />
              <circle cx='39' cy='116' r='39' />
              <circle cx='39' cy='256' r='39' />
              <circle cx='39' cy='396' r='39' />
            </svg>
            <p style={{whiteSpace: 'nowrap'}}>Protocolos <br />e vantagens</p>
          </li>
          <hr className='darken25' />
          <li
            data-path='/perfil'
            className={menu === '/perfil' ? 'active' : ''}
            onClick={handleLi}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path style={menu === '/perfil' ? {opacity: 1} : {opacity: 0}} d='M256.31,0C323.37.81,376.12,50.81,376,120.27c-.12,67.79-50.3,119.79-120,119.73-70.41-.06-120.25-52.8-120-120.4C136.23,49.92,189.45.44,256.31,0Z M256,512H71.34a72.25,72.25,0,0,1-12.29-.81c-14.55-2.5-24-10.78-28.34-24.65a43.56,43.56,0,0,1-1.45-16.84c1.18-14.6,3.09-29.07,7.49-43.09,16-51.12,49.35-87.18,98.16-109.41,18.21-8.3,37.7-12.53,57.75-13.72,38.53-2.3,77.14-1.36,115.68-.76,34.45.55,66.88,9.54,95.73,29.09,38.77,26.25,64,61.65,74.38,107.21A190.79,190.79,0,0,1,483,475.19a34.68,34.68,0,0,1-30.59,36.11,116.32,116.32,0,0,1-11.65.68Z' />
              <path style={menu === '/perfil' ? {opacity: 0} : {opacity: 1}} d='M256.31,0C323.37.81,376.12,50.81,376,120.27c-.12,67.79-50.3,119.79-120,119.73-70.41-.06-120.25-52.8-120-120.4C136.23,49.92,189.45.44,256.31,0Zm-.54,208.66c47.85.5,89-36,89-88.52,0-51.21-39.26-88.58-88.33-88.85-49.29-.27-89.5,37.1-89.37,89C167.28,174.07,209.74,209.05,255.77,208.66Z M256,512H71.34a72.25,72.25,0,0,1-12.29-.81c-14.55-2.5-24-10.78-28.34-24.65a43.56,43.56,0,0,1-1.45-16.84c1.18-14.6,3.09-29.07,7.49-43.09,16-51.12,49.35-87.18,98.16-109.41,18.21-8.3,37.7-12.53,57.75-13.72,38.53-2.3,77.14-1.36,115.68-.76,34.45.55,66.88,9.54,95.73,29.09,38.77,26.25,64,61.65,74.38,107.21A190.79,190.79,0,0,1,483,475.19a34.68,34.68,0,0,1-30.59,36.11,116.32,116.32,0,0,1-11.65.68Zm-.29-34H437.29c1.84,0,3.69.05,5.54,0,5.13-.25,5.87-1,5.87-6.3a99.51,99.51,0,0,0-1.25-14.53c-5-33.68-19.33-62.55-45.14-85.54-17.64-15.72-38.18-25.76-61.13-31.18a192.68,192.68,0,0,0-44.54-4.69H214.79a204.24,204.24,0,0,0-31.24,2.3c-33.59,5.16-62.38,19.35-85.07,44.78C76.92,407,66,435.53,63.55,467.41c-.79,10.53-.47,10.57,10,10.57Z' />
            </svg>
            <p>Perfil</p>
          </li>
          <li
            data-path='/faqs'
            className={menu === '/faqs' ? 'active' : ''}
            onClick={handleLi}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path style={menu === '/faqs' ? {opacity: 1} : {opacity: 0}} d='M256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.38,512,256,397.38,0,256,0Zm-9,412a27,27,0,1,1,27-27A27,27,0,0,1,247,412ZM292.1,260.63C268.22,276.29,265,291.35,265,304a16,16,0,1,1-31.93,0c0-28.86,13.59-51.8,41.54-70.13,27.76-18.2,43.46-29.77,43.46-55.84,0-17.7-10.71-31.66-31.83-41.49-4.78-2.22-15.52-4.75-30-4.57-17.24.25-30.7,4.36-41.1,12.55-19.5,15.34-21.18,32.43-21.19,32.59A16,16,0,1,1,162,175.55c.16-3.18,2.42-31.83,33.35-56.16,15.88-12.5,36.2-19,60.4-19.38,17.15-.22,33.16,2.54,43.93,7.55C332.14,122.66,350,147.69,350,178,350,222.66,319.24,242.83,292.1,260.63Z' />
              <path style={menu === '/faqs' ? {opacity: 0} : {opacity: 1}} d='M256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.38,512,256,397.38,0,256,0Zm0,480C132.29,480,32,379.71,32,256S132.29,32,256,32,480,132.29,480,256,379.71,480,256,480Z M249,320a16,16,0,0,1-16-16c0-28.86,13.59-51.8,41.54-70.13,27.76-18.2,43.46-29.77,43.46-55.84,0-17.7-10.71-31.66-31.83-41.49-4.78-2.22-15.52-4.75-30-4.57-17.24.25-30.7,4.36-41.1,12.55-19.5,15.34-21.18,32.43-21.19,32.59A16,16,0,1,1,162,175.55c.16-3.18,2.42-31.83,33.35-56.16,15.88-12.5,36.2-19,60.4-19.38,17.15-.22,33.16,2.54,43.93,7.55C332.14,122.66,350,147.69,350,178c0,44.62-30.76,64.79-57.9,82.59C268.22,276.29,265,291.35,265,304A16,16,0,0,1,249,320Z' />
              <circle style={menu === '/faqs' ? {opacity: 0} : {opacity: 1}} cx="246.96" cy="385" r="27"/>
            </svg>
            <p>Perguntas <br />Frequentes</p>
          </li>
        </ul>
        <figure onClick={closeMenu}>
          <Logo />
        </figure>
      </nav>
    </aside>
    </>
  )
}