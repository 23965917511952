import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Login.css'
import './Validate.css'
import Logo from '../Logo'
import { useParams } from 'react-router-dom'

export default function Recover() {

	const navigate = useNavigate();
	const {token} = useParams();

	// focus on load
	const firstRef = useRef(null);
	useEffect(() => {
		firstRef.current.focus();
	}, []);

	// prevent spaces
	const handlePrevent = (e) => {
		if (e.keyCode === 32) {
			e.preventDefault();
			return false;
		};
	};

	// invalid event
	/* const [userIsInvalid, setUserIsInvalid] = useState(false);
	const validation = (e) => {
		e.preventDefault();
		if(e.currentTarget.type === 'password' && !e.currentTarget.validity.valid) {
			setUserIsInvalid(true);
			setTimeout(() => {
				setUserIsInvalid(false);
			}, 1125);
		};
	}; */

	const [pass1, setPass1] = useState('');
	const [pass2, setPass2] = useState('');
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');
	const [errorStatus, setErrorStatus] = useState(false);

	const handleSubmitPw = async (e) => {
		e.preventDefault();
		const error = document.querySelector('.error');
		
		if(!success) {

			let origin;
			if(window.location.host === 'localhost:3000') {
				origin = `${window.location.protocol}//${window.location.hostname}:80`;
			} else {
				origin = window.location.origin;
			}
			/* fetch */
			try {
				const res = await fetch(`${origin}/checkpassword`, {
					method: 'POST',
					body: JSON.stringify({ token, pass1, pass2 }),
					headers: { 'Content-type': 'application/json' },
				})
				const data = await res.json();
				if(data.errors) {
					setError(data.errors.login);
					setErrorStatus(true);
					setTimeout(() => {
						setErrorStatus(false);
					}, 4000);
					if(data.errors.login === 'O periodo para redefinir a senha expirou. Por favor aguarde...') {
						document.documentElement.setAttribute('id', 'loader');
						setErrorStatus(true);
						setTimeout(() => {
							setErrorStatus(false);
						}, 4000);
						setTimeout(() => {
							document.documentElement.removeAttribute('id', 'loader');
							navigate('/reset');
						}, 4000);
					}
				}
				if(data.msg) {
					document.documentElement.setAttribute('id', 'loader');
					setError(data.msg);
					localStorage.setItem('firstTime', false)
					setSuccess(true);
					setErrorStatus(true);
					setTimeout(() => {
						setErrorStatus(false);
					}, 4000);
					setTimeout(() => {
						document.documentElement.removeAttribute('id', 'loader');
						navigate('/');
					}, 4000);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}
	
	const [pwTypeAlt, setPwTypeAlt] = useState(true);
	const [eyeAlt, setEyeAlt] = useState('M332,200H318.23V159.51c0-67.87-55.27-123-123.59-123.5H193C124.52,36.37,69.1,91.55,69.1,159.51V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200ZM124.46,159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62V200H124.46Z');
	const togglePasswordAlt = (e) => {
		e.cancelable && e.preventDefault();
		setPwTypeAlt(!pwTypeAlt);
		if(pwTypeAlt) {
			setEyeAlt('M388.41,36h-1.66c-68.46.36-123.88,55.54-123.88,123.5V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200H318.23V159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62v41.17a27.68,27.68,0,0,0,55.36,0V159.51C512,91.64,456.73,36.53,388.41,36Z')
		} else {
			setEyeAlt('M332,200H318.23V159.51c0-67.87-55.27-123-123.59-123.5H193C124.52,36.37,69.1,91.55,69.1,159.51V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200ZM124.46,159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62V200H124.46Z');
		}
	}

	return (
		<div className='main-div'>
			<Logo />
			<form id='form' onSubmit={handleSubmitPw} className='userSelect'>
				<h1>
					<span>Redefinir</span>
					<br />
					<span>a </span>
					<span>Senha</span>
				</h1>

				{/* PASSWORD */}
				<div className='section'>
					<label htmlFor='formPass1'>Nova senha</label>
					<div className='input-div'>
						<input
							ref={firstRef}
							type={pwTypeAlt ? 'password' : 'text'}
							id='formPass1'
							name='formPass1'
							placeholder='senha'
							title=''
							required
							spellCheck='false'
							style={{userSelect: 'none'}}
							onKeyDown={handlePrevent}
							/* onInvalid={validation} */
							/* className={userIsInvalid ? 'validation' : ''} */
							onChange={(e) => setPass1(e.target.value)}
						/>
						<svg className='eye' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
						onClick={togglePasswordAlt}>
							<path d={eyeAlt} fill='var(--dark)' />
						</svg>
					</div>
				</div>
				{/* PASSWORD 2 */}
				<div className='section'>
					<label htmlFor='formPass2'>Confirmar nova senha</label>
					<div className='input-div'>
						<input
							type={pwTypeAlt ? 'password' : 'text'}
							id='formPass2'
							name='formPass2'
							placeholder='senha'
							title=''
							required
							spellCheck='false'
							onKeyDown={handlePrevent}
							/* onInvalid={validation} */
							/* className={userIsInvalid ? 'validation' : ''} */
							onChange={(e) => setPass2(e.target.value)}
						/>
						<svg className='eye' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
						onClick={togglePasswordAlt}>
							<path d={eyeAlt} fill='var(--dark)' />
						</svg>
					</div>
				</div>

				{/* BUTTON */}
				<button type='submit' id='submit'>Confirmar</button>

				{/* RECOVERY */}
				<h6 className='recovery'>
					<span>Sabe a sua senha? <br />Iniciar sessão&nbsp;</span>
					<span onClick={() => navigate('/login')}>aqui</span>
				</h6>
				
				{/* ERROR */}
				<div className={errorStatus ? 'error status' : 'error'}>
					<p>{error}</p>
				</div>
			</form>
		</div>
	)
}
