import { useState, useEffect, useRef } from 'react';
import './Menu.css';
import Plano from './articles/Plano';
import Declaracao from './articles/Declaracao';

export default function Menu(props) {

  /* already clicked */
  const [clickedNewsletter, setClickedNewsletter] = useState(false);
  const [clickedRecibo, setClickedRecibo] = useState(false);
  const [clickedIndice, setClickedIndice] = useState(false);

  const [pdfMonth, setPdfMonth] = useState('');
  const [pdfFileName, setPdfFileName] = useState(''); // ult
  const [pdfFileSize, setPdfFileSize] = useState('0 KB');
  const [noData, setNoData] = useState(false);
	
	const fetchLatestRecVenc = async () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
		try {
			const res = await fetch(`${origin}/readlatestpdf`, {
				method: 'GET',
        headers: { 'Content-type': 'application/json' },
				credentials: 'include',
			})
			const data = await res.json();
			if(data[0] !== null && data.length > 0) {
        setPdfMonth(data[0].DATA);
        setPdfFileName(data[0].PDF_FILE_NAME);
        if(pdfFileName === '') {
          setPdfFileName(data[0].PDF_FILE_NAME);
        }
        setPdfFileSize(data[0].TAMANHO);
			} else {
				console.log('err');
        setNoData(true);
			}
		} catch (error) {
      setNoData(true);
			console.log(error);
		}
		/* return */
	}

  /* NEWSLETTER */
  const handleNewsletter = (e) => {
    /* cancelable becomes false if touchmove occurs */
    setClickedNewsletter(true);
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    fetch(`${origin}/downloadnewsletter`, {
      method: 'GET',
      headers: { 'Content-type': 'application/pdf' },
      credentials: 'include',
      responseType: 'blob',
    })
    .then(res => res.blob())
    .then(response => {
      const file = new Blob([response], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement('a');
      link.download = '';
      link.target = '_blank';
      link.id = 'test';
      link.href = fileURL;
      /* window.open(fileURL); */
      link.click();
      setClickedNewsletter(false);
    })
    .catch(error => {
      console.log(error);
    });
	}

  const handleDownloadRecibo = (e) => {
    if(pdfFileName === '') {
      console.log('ult');
    } else {
      setClickedRecibo(true);
      let origin;
      if(window.location.host === 'localhost:3000') {
        origin = `${window.location.protocol}//${window.location.hostname}:80`;
      } else {
        origin = window.location.origin;
      }
      /* fetch */
      fetch(`${origin}/downloadlatestpdf?id=${pdfFileName}`, {
        method: 'GET',
        headers: { 'Content-type': 'application/pdf' },
        credentials: 'include',
        responseType: 'blob',
      })
      .then(res => res.blob())
      .then(response => {
        const file = new Blob([response], {
          type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.download = '';
        link.target = '_blank';
        link.id = 'test';
        link.href = fileURL;
        /* window.open(fileURL); */
        link.click();
        setTimeout(() => {
          setClickedRecibo(false);
        }, 2500);
      })
      .catch(error => {
        console.log('ult err', error);
      });
    }
	}

  const handleDownloadIndice = (e) => {
    setClickedIndice(true);
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    fetch(`${origin}/downloadindice`, {
      method: 'GET',
      headers: { 'Content-type': 'application/pdf' },
      credentials: 'include',
      responseType: 'blob',
    })
    .then(res => res.blob())
    .then(response => {
      const file = new Blob([response], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement('a');
      link.download = '';
      link.target = '_blank';
      link.id = 'testAlt';
      link.href = fileURL;
      /* window.open(fileURL); */
      link.click();
      setClickedIndice(false);
    })
    .catch(error => {
      console.log('ult err', error);
    });
	}

  // USEEFFECT
	useEffect(() => {
		fetchLatestRecVenc();
    setPadding(window.screen.width - menuRef.current.getBoundingClientRect().right);
    setTop(menuRef.current.getBoundingClientRect().top);
	}, [])
  
  // menu max height
  const [padding, setPadding] = useState(0);
  const [top, setTop] = useState(0);
  const menuRef = useRef(null);

  return (
    <menu ref={menuRef} style={{height: `calc(100% - ${top}px + ${padding}px)`}}>

      {/* Newsletter */}
      <article className='newsletter cursor-pointer'
        onClick={clickedNewsletter ? null : handleNewsletter}
      >
        <div>
          <img src='/newsletter.jpg' alt='' />
          <img className='BG' src='/newsletterBG.jpg' alt='' />
          {/* loader */}
          <blockquote
            className={clickedNewsletter ? 'loader' : 'loader pointer-events fade'}
          ></blockquote>
        </div>
      </article>

      {/* Plano */}
      <Plano cursorPointer details={props.info} cat={props.cat} />

      {/* Recibo vencimento */}
      <article
        className={noData ? 'recibo gap-zero cursor-pointer darken' : 'recibo gap-zero cursor-pointer'}
        style={noData ? {pointerEvents: 'none', userSelect: 'none'} : {}}
        onClick={clickedRecibo ? null : handleDownloadRecibo}
      >
        <h3>Recibo de Vencimento <br />{pdfMonth}</h3>
        <div className='PDFinfo'>
          <svg className='pdf' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M440,117.5l-9.81-9.86h0L339.16,16.06A54,54,0,0,0,300.6,0H128.73A72.07,72.07,0,0,0,77.3,21.42,72.86,72.86,0,0,0,56,73.14V438.86a72.86,72.86,0,0,0,21.3,51.72A72.07,72.07,0,0,0,128.73,512H383.27a72.07,72.07,0,0,0,51.43-21.42A72.92,72.92,0,0,0,456,438.86V156.29h0A54.66,54.66,0,0,0,440,117.5ZM419.64,438.86A36.47,36.47,0,0,1,409,464.72a36.06,36.06,0,0,1-25.72,10.71H128.73A36.06,36.06,0,0,1,103,464.72a36.47,36.47,0,0,1-10.65-25.86V73.14A36.47,36.47,0,0,1,103,47.28a36.06,36.06,0,0,1,25.72-10.71H300.6a18,18,0,0,1,12.85,5.35L404.5,133.5h0l9.81,9.86a18.26,18.26,0,0,1,5.33,12.93Z M392,316a16,16,0,0,1,0,32H360v48a16,16,0,0,1-32,0V268a16,16,0,0,1,16-16h48a16,16,0,0,1,0,32H360v32Z M256,252H232a16,16,0,0,0-16,16V396a16,16,0,0,0,16,16h24a48,48,0,0,0,48-48V300A48,48,0,0,0,256,252Zm16,112a16,16,0,0,1-16,16h-8V284h8a16,16,0,0,1,16,16Z M144,252H120a16,16,0,0,0-16,16V396a16,16,0,0,0,32,0V364h8a56,56,0,0,0,0-112Zm0,80h-8V284h8a24,24,0,0,1,0,48Z' fill='var(--pdf)'/>
          </svg>
          <p>{pdfFileSize}</p>
        </div>
        <span>
          <p>descarregar</p>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M447.62,458H64.38C49.26,458,37,469.88,37,484.53A27.19,27.19,0,0,0,64.38,512H447.62c15.12,0,27.38-11.88,27.38-26.53S462.8,458,447.62,458Z M382.5,200.69l-99,105.09V27.53a27.47,27.47,0,1,0-54.94-.09V305.69l-99.06-105a27.43,27.43,0,1,0-40,37.54L236,393.7c10.37,11.07,29.63,11.07,40,0L422.53,238.23a27.36,27.36,0,0,0-1.18-38.75C410.31,189,392.91,188.59,382.5,200.69Z'/>
          </svg>
        </span>
        {/* loader */}
        <blockquote
          className={clickedRecibo ? 'loader' : 'loader pointer-events fade'} 
        ></blockquote>
      </article>

      {/* Declaração */}
      <Declaracao />

      {/* Informação Sinistralidade */}
      <article
        className='sinistralidade gap-zero cursor-pointer'
        onClick={clickedIndice ? null : handleDownloadIndice}
      >
        <h3>Acidentes e Sinistralidade <br />2021</h3>
        <div className='PDFinfo'>
          <svg className='pdf' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M440,117.5l-9.81-9.86h0L339.16,16.06A54,54,0,0,0,300.6,0H128.73A72.07,72.07,0,0,0,77.3,21.42,72.86,72.86,0,0,0,56,73.14V438.86a72.86,72.86,0,0,0,21.3,51.72A72.07,72.07,0,0,0,128.73,512H383.27a72.07,72.07,0,0,0,51.43-21.42A72.92,72.92,0,0,0,456,438.86V156.29h0A54.66,54.66,0,0,0,440,117.5ZM419.64,438.86A36.47,36.47,0,0,1,409,464.72a36.06,36.06,0,0,1-25.72,10.71H128.73A36.06,36.06,0,0,1,103,464.72a36.47,36.47,0,0,1-10.65-25.86V73.14A36.47,36.47,0,0,1,103,47.28a36.06,36.06,0,0,1,25.72-10.71H300.6a18,18,0,0,1,12.85,5.35L404.5,133.5h0l9.81,9.86a18.26,18.26,0,0,1,5.33,12.93Z M392,316a16,16,0,0,1,0,32H360v48a16,16,0,0,1-32,0V268a16,16,0,0,1,16-16h48a16,16,0,0,1,0,32H360v32Z M256,252H232a16,16,0,0,0-16,16V396a16,16,0,0,0,16,16h24a48,48,0,0,0,48-48V300A48,48,0,0,0,256,252Zm16,112a16,16,0,0,1-16,16h-8V284h8a16,16,0,0,1,16,16Z M144,252H120a16,16,0,0,0-16,16V396a16,16,0,0,0,32,0V364h8a56,56,0,0,0,0-112Zm0,80h-8V284h8a24,24,0,0,1,0,48Z' fill='var(--pdf)'/>
          </svg>
        </div>
        <span>
          <p>descarregar</p>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M447.62,458H64.38C49.26,458,37,469.88,37,484.53A27.19,27.19,0,0,0,64.38,512H447.62c15.12,0,27.38-11.88,27.38-26.53S462.8,458,447.62,458Z M382.5,200.69l-99,105.09V27.53a27.47,27.47,0,1,0-54.94-.09V305.69l-99.06-105a27.43,27.43,0,1,0-40,37.54L236,393.7c10.37,11.07,29.63,11.07,40,0L422.53,238.23a27.36,27.36,0,0,0-1.18-38.75C410.31,189,392.91,188.59,382.5,200.69Z'/>
          </svg>
        </span>
        {/* loader */}
        <blockquote
          className={clickedIndice ? 'loader' : 'loader pointer-events fade'} 
        ></blockquote>
      </article>
    </menu>
  )
}