import './Dashboard.css'

export default function Dashboard(props) {

  return (
    <ul className='dashboard-ul'>
      <li className='dashboard-txt'>
        <h4>A sua carreira</h4>
      </li>
      <hr />
      <li className='ellipse'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className='selected'>{props.details.inicioMes}&nbsp; '{props.details.inicioAno}</h6>
      </li>
      {/*  */}
      <li className={props.details.nivel > 0 ? 'line-container' : 'line-container darken25'}>
        <div className="line"></div>
      </li>
      <li>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          </svg>
        </div>
      </li>
      {/*  */}
      <li className={props.details.nivel > 0 ? 'ellipse' : 'darken25 ellipse'}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className={props.details.nivel > 0 ? 'selected' : 'darken25'}>{props.details.inicioMes}&nbsp; '{props.details.inicioAno + 1}</h6>
      </li>
      {/*  */}
      <li className={props.details.nivel > 1 ? 'line-container' : 'line-container darken25'}>
        <div className="line"></div>
      </li>
      <li className={props.details.nivel > 0 ? '' : 'darken25'}>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
        </div>
      </li>
      {/*  */}
      <li className={props.details.nivel > 1 ? 'ellipse' : 'darken25 ellipse'}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className={props.details.nivel > 1 ? 'selected' : 'darken25'}>{props.details.inicioMes}&nbsp; '{props.details.inicioAno + 2}</h6>
      </li>
      {/*  */}
      <li className={props.details.nivel > 2 ? 'line-container' : 'line-container darken25'}>
        <div className="line"></div>
      </li>
      <li className={props.details.nivel > 1 ? '' : 'darken25'}>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
        </div>
      </li>
      {/*  */}
      <li className={props.details.nivel > 2 ? 'ellipse' : 'darken25 ellipse'}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className={props.details.nivel > 2 ? 'selected' : 'darken25'}>{props.details.inicioMes}&nbsp; '{props.details.inicioAno + 3}</h6>
      </li>
      {/*  */}
      <li className={props.details.nivel > 3 ? 'line-container' : 'line-container darken25'}>
        <div className="line"></div>
      </li>
      <li className={props.details.nivel > 2 ? '' : 'darken25'}>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
        </div>
      </li>
      {/*  */}
      <li className={props.details.nivel > 3 ? 'ellipse' : 'darken25 ellipse'}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className={props.details.nivel > 3 ? 'selected' : 'darken25'}>{props.details.inicioMes}&nbsp; '{props.details.inicioAno + 4}</h6>
      </li>
      {/*  */}
      <li className={props.details.nivel > 4 ? 'line-container' : 'line-container darken25'}>
        <div className="line"></div>
      </li>
      <li className={props.details.nivel > 3 ? '' : 'darken25'}>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
        </div>
      </li>
      {/*  */}
      <li className={props.details.nivel > 4 ? 'ellipse' : 'darken25 ellipse'}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <ellipse cx='256' cy='256' rx='256' ry='256' fill='var(--red)'/>
        </svg>
      </li>
      <li>
        <h6 className={props.details.nivel > 4 ? 'selected' : 'darken25'}>{props.details.inicioMes}&nbsp; '{props.details.inicioAno + 5}</h6>
      </li>
      {/*  */}
      <li className='line-container'>
        {/* <div className="line"></div> */}
      </li>
      <li className={props.details.nivel > 4 ? 'last-star' : 'last-star darken25'}>
        <div className='star-container'>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
          <svg className='star' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
          </svg>
        </div>
      </li>
      {/* <hr />
      <li className='dashboard-txt'>
        <p>Prémio atual:</p>
        <h6 className={props.details.premio === 'N/A' ? 'darken25' : ''}>{props.details.premio}</h6>
      </li>
      <hr />
      <li className='dashboard-txt'>
        <p>Prémio próximo ano:</p>
        <h6 className={props.details.premioProximo === 'N/A' ? 'darken25' : ''}>{props.details.premioProximo}</h6>
      </li> */}
    </ul>
  )
}
