import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Rank from '../../SVGS/Rank'
import Dashboard from './Dashboard';
import './Plano.css'
import './Dashboard.css'

export default function Plano(props) {

  const navigate = useNavigate();
  const [historyOpen, setHistoryOpen] = useState(false);

  const handleHistory = (e) => {
    if(e.currentTarget.className === 'historico' && !historyOpen) {
      document.body.classList.add('noScroll');
      setHistoryOpen(true);
    }
    if(e.target.className === 'dashboard' && historyOpen) {
      document.body.classList.remove('noScroll');
      setHistoryOpen(false);
    }
  }  
  
  return (
    <>
    <article
      className={props.cat !== 'Vigilante' ? 'plano relativeSVG none' : 'plano relativeSVG'}
      style={props.cursorPointer ? {cursor: 'pointer'} : {}}
      onClick={props.cursorPointer ? () => navigate('/perfil') : () => {return}}
    >
      <h3>Plano de <br className='tablet-br' />Motivação <br className='land-br' />e Carreira</h3>
      <figure>
        <Rank lvl={props.details.nivel} />
      </figure>
      <div className='group'>
        <p className='plano-p'><strong style={{color: 'var(--red)'}}>Categoria:</strong>&nbsp; {props.cat}</p>
        <hr className='plano-hr' />
        <button className='historico' onClick={handleHistory}>
          Histórico
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path d='M265.31,126.52a26.14,26.14,0,0,0-26.07,26.07V283a26.09,26.09,0,0,0,10.9,21.22L311,347.66a25.11,25.11,0,0,0,14.18,4.86,26.08,26.08,0,0,0,15.17-47.3l-48.95-35.61v-117A26.07,26.07,0,0,0,265.31,126.52Z M264.83,8C176.77,8,96.77,56.24,53,129.9V34.57a26.49,26.49,0,1,0-53,0V194a26.49,26.49,0,0,0,26.48,26.57H167.72a26.57,26.57,0,0,0,0-53.14H92.18C124.91,103.55,191.45,61.1,264.83,61.1,371.9,61.1,459,148.57,459,256S371.9,449.82,264.83,449.82a193.91,193.91,0,0,1-124.25-45.09,26.54,26.54,0,0,0-33.93,40.83A242.43,242.43,0,0,0,264.83,504C401.1,504,512,392.73,512,256S401.1,8,264.83,8Z'/>
          </svg>
        </button>
      </div>
      <span className='span-link'>
        <p>saber mais</p>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <path d='M302.37,44.55,503.5,236.17a27.37,27.37,0,0,1,0,39.65L302.37,467.45a27.4,27.4,0,1,1-37.85-39.64L416.16,283.4H27.44a27.38,27.38,0,1,1,0-54.75H416.09L264.45,84.25a27.45,27.45,0,0,1-.93-38.77C274,34.53,290.38,34.14,302.37,44.55Z'/>
          </svg>
      </span>
      {/* dashboard */}
      
      {/* banner */}
      <svg id='BANNERsvg' className='absoluteSVG' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path d='M96,0V490.4c0,16.65,12.56,27,22.56,18.63L248.44,400.11a11.19,11.19,0,0,1,15.12,0L393.44,509c10,8.39,22.56-2,22.56-18.63V0ZM314,144.63l-16.25,17.6h-7c-17.88,18.94-35.8,37.93-54.08,57.28-6.79-4.81-12-10.85-18.25-16.59,4.71-7.09,11.05-12.5,16.53-18.58,11.75-13.07,23.91-25.78,36.06-38.5C282.13,134.17,301.89,133.48,314,144.63Zm-106.51-11a20.79,20.79,0,0,1,16.09,0c10.49,4.35,18.7,11.82,26.94,20.27l-26.46,24.77c-7.27-2.8-13.22-17.41-23.92-2.64l-21.09-19.86C187.76,147.05,196,138.36,207.5,133.62Zm-35.72,34.07c1.24-2.71,2.08-5.79,5-8.06,6.9,6.35,13.69,12.62,20.61,19-2.27,6.37,1.1,10.44,5.25,14.39,18,17.08,35.81,34.26,53.76,51.35,2.23,2.13,4.83,3.89,8,6.42l-20.77,22c-8.81-8.55-17.18-16.62-25.5-24.73q-19.2-18.71-38.35-37.46C167.77,198.81,164.76,183,171.78,167.69Zm-3.43,103c-2.75-7-.95-13.59,2.35-19.84,4.71-8.89,11.48-16.21,19.33-24.33,6.12,6.46,11.63,12.28,17.44,18.43-14.48,13.08,3,17.68,7.54,26.65l-25.32,27.09C180.73,290,172.71,281.74,168.35,270.64Zm164-44.46c-3,5.2-7,9.62-11.08,13.95-21.79,23-42.42,47.1-65.05,69.31-19.3,18.95-31.18,19.17-52.24,2-2.92-2.38-5.61-5.05-8.22-7.42.5-2.51,2-3.77,3.28-5.14,7.14-7.63,14.33-15.21,21.7-23,2.84,2.59,5.22,4.89,7.74,7,6.26,5.26,8.89,5.31,14.59-.62q23.29-24.21,46.3-48.68c13.08-13.84,26.05-27.78,39.07-41.66C337.21,200.06,338.78,214.88,332.31,226.18ZM267,247.39c-10.58-10.3-12-24.93-2.85-34.91,19.61-21.42,39.65-42.46,59.52-63.64A24,24,0,0,1,326.2,147L345,164.61Z' fill='var(--red)'/>
      </svg>
    </article>
    <section className={historyOpen ? 'dashboard' : 'dashboard fade'} onClick={handleHistory}>
      <Dashboard details={props.details} />
    </section>
    </>
  )
}
