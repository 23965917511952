import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css'

export default function Header() {

  const navigate = useNavigate();

  const handleLogout = () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    if(window.confirm('Tem a certeza que pretende encerrar a sua sessão ?')) {
      fetch(`${origin}/logout`, {
        method: 'GET',
        credentials: 'include',
      })
      localStorage.removeItem('initName');
      localStorage.removeItem('cat');
      navigate('/login');
      window.location.reload();
    }
	}
  /* const test = () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    fetch(`${origin}/test`, {
      method: 'GET',
      credentials: 'include',
    })
	} */

  const [door, setDoor] = useState(false);

  return (
		<header>
      &nbsp;
      <figure>
      <svg className='notifications darken25' style={{cursor: 'default'}} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' /* onClick={test} */>
          <path d='M255.67,416Q167.35,416,79,416c-9.54,0-18.38-1.89-26.47-7.26-25.16-16.72-25.63-48.1-10-67,2.37-2.86,5.53-4.7,8-7.37a169.71,169.71,0,0,0,26.86-38.1c10.38-20.06,15.89-41.44,17.71-63.94,1.08-13.37.63-26.72.78-40.07.31-25.92,5.46-50.74,17.53-73.77a160.45,160.45,0,0,1,65.16-66.88,154.41,154.41,0,0,1,53.85-17.81c6.54-.94,6.92-1.47,6.17-8.22-.51-4.64-.86-9.27.72-13.83C242.05,4,248.73-.58,256.52.06c8.78.72,14.35,5.6,15.58,14.27.57,4,.32,8.08.41,12.13.14,5.86.61,6.51,6.45,7.32a158.28,158.28,0,0,1,38,10.15c31.19,12.67,55.34,33.86,73.87,61.69,13.63,20.46,20.76,43.27,23.82,67.54,1.53,12.13,1.17,24.26,1.13,36.4-.06,16.46,1.07,32.78,4.73,48.86,6.63,29.12,20.59,54.35,40.9,76.13,2.88,3.1,6.46,5.46,9,9,10.46,14.81,12.89,30.88,4.79,47-8,15.89-21.34,25.29-39.82,25.32q-89.84.15-179.68,0Z M246.83,512c-22.59-.32-43.58-21.57-51.63-43.61-3.22-8.85.22-16.93,8.19-20.68,8.17-3.84,16.75-1,21.56,7.11,1.45,2.47,2.59,5.11,4,7.63,11.9,22,42.1,18.31,52.09,2.94,2.33-3.59,4.11-7.54,6.38-11.18,6.33-10.13,19.32-11,27-1.92,4.31,5.11,4.38,10.88,2.15,16.78a65.52,65.52,0,0,1-11,19.08C292.3,504,275.25,511.89,246.83,512Z'/>
        </svg>
        <svg className='logout' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' onClick={handleLogout} onMouseOver={() => setDoor(true)} onMouseLeave={() => setDoor(false)}>
          <path d='M478.93,477.87h-51.2V34.13A34.13,34.13,0,0,0,393.6,0H118.4A34.13,34.13,0,0,0,84.27,34.13V477.87H33.07a17.07,17.07,0,1,0,0,34.13H478.93a17.07,17.07,0,1,0,0-34.13ZM324,308a35,35,0,1,1,35-35A35,35,0,0,1,324,308Z'/>
        </svg>
      </figure>
		</header>
  )
}
