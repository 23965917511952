import { useState, useEffect, useRef } from 'react';
import './Menu.css';
import './MenuFaqs.css';

export default function MenuFaqs() {

  // ref
  /* useEffect(() => {
    setPadding(window.screen.width - menuRef.current.getBoundingClientRect().right);
    setTop(menuRef.current.getBoundingClientRect().top);
  }, [])

  // menu max height
  const [padding, setPadding] = useState(0);
  const [top, setTop] = useState(0);
  const menuRef = useRef(null); */
  
  const faqs = [
    {
      title: <>Como funciona o novo '<i>Plano de Motivação e Carreira</i>' ?</>,
      content: <>
        No dia 1 de Outubro de 2022 foi implementado, pela primeira vez, o "<i>Plano de Motivação e Carreira</i>".
        <br /><br />
        Este plano é somente aplicável a colaboradores cuja categoria profissional se designe de "Vigilante" e pretende, assim, gratificar cada colaborador por uma carreira exemplar e de acordo com os seus anos de serviço na <b>Powershield</b>.
        <br /><br />
        A partir do dia 01 de Outubro de 2022, e <strong>por cada 1 (um) ano de serviço</strong>, cada colaborador receberá um <strong>nível</strong> (automaticamente associado ao seu perfil de colaborador), designado por uma <strong>estrela</strong>, significante do seu progresso até esta data e que, por sua vez, trará <strong>prémios adicionais</strong> anuais para o próprio colaborador.
        <br /><br />
        Este progresso/nível, assim como outras informações, pode ser consultado neste Portal do Colaborador.
        <br /><br />
        Este plano contempla um <strong>teto máximo de 5 níveis</strong> e um <strong>prémio máximo anual</strong> de acordo com os critérios aplicados a este plano.
        <br /><br />
        Qualquer falta que não seja justificada dentro do período para o efeito resultará na <strong>perda do prémio no respectivo ano</strong> ou, caso o prémio já tenha sido entregue no respectivo ano, na <strong>perda do prémio no ano seguinte</strong>.
      </>
    },
    {
      title: <>O que está contemplado no '<i>Plano de Motivação e Carreira</i>' ?</>,
      content: <>
        Este plano contempla prémios, atribuídos anualmente, para além de todas as remunerações que o colaborador já aufere, de acordo com o seu percurso na Powershield.
        <br />
        Os prémios serão atribuídos de acordo com os seguintes critérios:
        <br /><br />
        <b>•</b> <strong>2º nível</strong> (2 anos)
        <br /><br />
        <b>•</b> <strong>3º nível</strong> (3 anos)
        <br /><br />
        <b>•</b> <strong>4º nível</strong> (4 anos)
        <br /><br />
        <b>•</b> <strong>5º nível</strong> (5 anos)
        <br /><br />
        <b>•</b> <strong>5º nível</strong> (6 a 10 anos)
        <br /><br />
        <b>•</b> <strong>5º nível</strong> (mais de 10 anos){/* : <strong>300€</strong> */}
        <br /><br />
        Cada colaborador receberá também, após atingir cada nível, uma plaqueta/crachá em metal, para colocação na farda, gravada com o número de estrelas correspondentes ao respetivo nível.
        <br /><br />
        <sup>A Powershield reserva o direito de, em qualquer caso, alterar os critérios acima descritos para atribuição dos prémios.</sup>
      </>
    },
  ]
  
  const [activeFaq, setActiveFaq] = useState('');
  const [blockHeight, setBlockHeight] = useState(0);
  const handleCollapse = (e) => {
    if(activeFaq !== e.currentTarget.dataset.index) {
      setActiveFaq(e.currentTarget.dataset.index);
    }
    if(activeFaq === e.currentTarget.dataset.index) {
      setActiveFaq('');
    }
    setBlockHeight(e.currentTarget.lastChild.scrollHeight);
  }
  
  useEffect(() => {
    /* console.log(activeFaq);
    console.log(blockHeight); */
  }, [activeFaq])

  const showFaqs = (el) => {
    return el.map(({ title, content }, index) => {
      return (
        <article
          onClick={handleCollapse}
          data-index={`faq-${index}`}
          className={activeFaq === `faq-${index}` ? 'active' : ''}
          style={activeFaq === `faq-${index}` ? {} : {gap: 0}}
          key={index}
        >
          <div className='container'>
            <h3>{title}</h3>
            <svg className={activeFaq === `faq-${index}` ? 'chevron rotate' : 'chevron'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path d='M9.14,152.93l218.41,255a37.48,37.48,0,0,0,56.94,0l218.41-255C523.75,128.59,506.47,91,474.43,91H37.55c-32,0-49.32,37.59-28.41,61.93Z' fill='var(--red)'/>
            </svg>
          </div>
          <blockquote style={activeFaq === `faq-${index}` ? {maxHeight: `${blockHeight}px`} : {}}>
            <hr />
            <p className='text'>{content}</p>
          </blockquote>
        </article>
			)
    })
	}

  return (
    <menu className='faqs' /* ref={menuRef} style={{height: `calc(100% - ${top}px + ${padding}px)`}} */>
      {showFaqs(faqs)}
    </menu>
  )
}