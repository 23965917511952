import { useState, useEffect, useRef } from 'react';
import './Menu.css';
import './MenuRecibos.css';

export default function MenuRecibos() {

  /* already clicked */
  const [clicked, setClicked] = useState(false);

  const [recs, setRecs] = useState([]);
	const fetchRecsVenc = async () => {
		let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
		try {
			const res = await fetch(`${origin}/readpdf`, {
				method: 'GET',
				credentials: 'include',
			})
			const data = await res.json();
			if(data) {
				setRecs([...data]);
			} else {
				console.log('err');
			}
		} catch (error) {
			console.log(error);
		}
		/* return */
	}

	const handleDownload = (e) => {
    setClicked(true);
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    fetch(`${origin}/downloadpdf?id=${e.currentTarget.id}`, {
      method: 'GET',
      headers: { 'Content-type': 'application/pdf' },
      responseType: 'blob',
      credentials: 'include',
    })
    .then(res => res.blob())
    .then(response => {
      const file = new Blob([response], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement('a');
      link.download = '';
      link.target = '_blank';
      link.id = 'test';
      link.href = fileURL;
      /* window.open(fileURL); */
      link.click();
      setTimeout(() => {
        setClicked(false);
      }, 2500);
    })
    .catch(error => {
      console.log(error);
    });
	}
	
	// USEEFFECT
	useEffect(() => {
		fetchRecsVenc();
    // ref
    setPadding(window.screen.width - menuRef.current.getBoundingClientRect().right);
    setTop(menuRef.current.getBoundingClientRect().top);
	}, [])

	// menu max height
  const [padding, setPadding] = useState(0);
  const [top, setTop] = useState(0);
  const menuRef = useRef(null);

  const showRecs = () => {
    return recs.map(({ DATA, DATANUM, NUMERO_RECIBO, PDF_FILE_NAME, TAMANHO }, index) => {
      return (
        <article
          key={NUMERO_RECIBO}
          id={`${PDF_FILE_NAME}`}
          onClick={clicked ? null : handleDownload}
        >
          <h4>{DATA}</h4>
          <div className='PDFinfo'>
            <svg className='pdf' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path d='M440,117.5l-9.81-9.86h0L339.16,16.06A54,54,0,0,0,300.6,0H128.73A72.07,72.07,0,0,0,77.3,21.42,72.86,72.86,0,0,0,56,73.14V438.86a72.86,72.86,0,0,0,21.3,51.72A72.07,72.07,0,0,0,128.73,512H383.27a72.07,72.07,0,0,0,51.43-21.42A72.92,72.92,0,0,0,456,438.86V156.29h0A54.66,54.66,0,0,0,440,117.5ZM419.64,438.86A36.47,36.47,0,0,1,409,464.72a36.06,36.06,0,0,1-25.72,10.71H128.73A36.06,36.06,0,0,1,103,464.72a36.47,36.47,0,0,1-10.65-25.86V73.14A36.47,36.47,0,0,1,103,47.28a36.06,36.06,0,0,1,25.72-10.71H300.6a18,18,0,0,1,12.85,5.35L404.5,133.5h0l9.81,9.86a18.26,18.26,0,0,1,5.33,12.93Z M392,316a16,16,0,0,1,0,32H360v48a16,16,0,0,1-32,0V268a16,16,0,0,1,16-16h48a16,16,0,0,1,0,32H360v32Z M256,252H232a16,16,0,0,0-16,16V396a16,16,0,0,0,16,16h24a48,48,0,0,0,48-48V300A48,48,0,0,0,256,252Zm16,112a16,16,0,0,1-16,16h-8V284h8a16,16,0,0,1,16,16Z M144,252H120a16,16,0,0,0-16,16V396a16,16,0,0,0,32,0V364h8a56,56,0,0,0,0-112Zm0,80h-8V284h8a24,24,0,0,1,0,48Z' fill='var(--pdf)'/>
              </svg>
            <p>{TAMANHO}</p>
            <p className='datanum'>{DATANUM}</p>
          </div>
          <span>
            <p>descarregar</p>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
              <path d='M447.62,458H64.38C49.26,458,37,469.88,37,484.53A27.19,27.19,0,0,0,64.38,512H447.62c15.12,0,27.38-11.88,27.38-26.53S462.8,458,447.62,458Z M382.5,200.69l-99,105.09V27.53a27.47,27.47,0,1,0-54.94-.09V305.69l-99.06-105a27.43,27.43,0,1,0-40,37.54L236,393.7c10.37,11.07,29.63,11.07,40,0L422.53,238.23a27.36,27.36,0,0,0-1.18-38.75C410.31,189,392.91,188.59,382.5,200.69Z'/>
            </svg>
          </span>
          {/* loader */}
          <blockquote
            className={clicked ? 'loader' : 'loader pointer-events fade'} 
          ></blockquote>
        </article>
			)
    })
	}

  return (
    <menu className='recibos' ref={menuRef} style={{height: `calc(100% - ${top}px + ${padding}px)`}}>

      <article>
				<h3>Recibos de Vencimento</h3>
			</article>
      <section id='container'>
        {showRecs()}
      </section>
    </menu>
  )
}