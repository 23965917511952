import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Manager from './components/Manager';
import Recover from './components/recovery/Recover';
import Validate from './components/recovery/Validate';
import './Reset.css';
import './App.css';

export default function App() {
  
  let local = useLocation();
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  
  //change <html> class for css
  useEffect(() => {
    if(local.pathname === '/login') {
      document.documentElement.classList = 'login init';
      document.title = 'Iniciar sessão | Portal do Colaborador';
    }
    if(local.pathname === '/reset') {
      document.documentElement.classList = 'login';
      document.title = 'Recuperar senha | Portal do Colaborador';
    }
    if(local.pathname.startsWith('/pw')) {
      document.documentElement.classList = 'login';
      document.title = 'Redefinir senha | Portal do Colaborador';
    }
    if((local.pathname === '/' && local.pathname !== '/login' && local.pathname !== '/reset' && !local.pathname.startsWith('/pw')) || local.pathname === '/recibos' || local.pathname === '/perfil' || local.pathname === '/protocolos' || local.pathname === '/faqs') {
      document.documentElement.classList = 'manager';
      document.title = 'Portal do Colaborador | Powershield';
    }
  }, [local])

  const check = async () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    try {
      const res = await fetch(`${origin}/loginExt`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
      const data = await res.json();
      if(data.hasToken) {
        if(!isAuth) {
          setIsAuth(true);
        }
        if(local.pathname === '/login' || local.pathname === '/' || local.pathname === '/reset' || local.pathname === '/:token') {
          navigate('/');
        }
        if(local.pathname === '/recibos') {
          navigate('/recibos');
        }
        if(local.pathname === '/protocolos') {
          navigate('/protocolos');
        }
        if(local.pathname === '/perfil') {
          navigate('/perfil');
        }
        if(local.pathname === '/faqs') {
          navigate('/faqs');
        }
        else {
          console.log('err');
        }
      } else {
        console.log('err2');
        if(isAuth) {
          setIsAuth(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // update state
  useEffect(() => {
    check();
    /* screen.orientation.lock("portrait"); */
  }, [])
 
  useEffect(() => {
    /* console.log(isAuth); */
  }, [isAuth])
 
  return (
    <div id="App">
      <Routes>
        <Route path='/login' element={<Login setIsAuth={setIsAuth} />} />
        <Route path='/reset' element={<Validate />} />
        <Route path='/pw:token' element={<Recover />} />
        <Route element={<PrivateRoute auth={isAuth} />}>
          <Route exact path='/' element={<Manager menu />} />
          <Route path='/recibos' element={<Manager recibos />} />
          <Route path='/perfil' element={<Manager perfil />} />
          <Route path='/protocolos' element={<Manager protocolos />} />
          <Route path='/faqs' element={<Manager faqs />} />
        </Route>
      </Routes>
    </div>
  )
}