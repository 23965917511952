import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'
import './Init.css'
import Logo from './Logo';

export default function Login({setIsAuth}) {

	const navigate = useNavigate();
	const firstTime = localStorage.getItem('firstTime');

	//focus on page load
	const firstRef = useRef(null)
	useEffect(() => {
		firstRef.current.focus();
	}, []);
	
	//prevent spaces
	const handlePrevent = (e) => {
		if (e.keyCode === 32) {
			e.preventDefault();
			return false;
		};
	};

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [errorStatus, setErrorStatus] = useState(false);

	let set = (re) => {
		setIsAuth(re);
	}

	//SUBMIT
	const handleSubmit = async (e) => {
		e.preventDefault();

		let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
		try {
			const res = await fetch(`${origin}/loginExt?id=${window.innerWidth}x${window.innerHeight}`, {
				method: 'POST',
				body: JSON.stringify({ email, password }),
				headers: { 'Content-type': 'application/json' },
				credentials: 'include'
			})
			const data = await res.json();
			if(data.errors) {
				setError(data.errors.login);
				setErrorStatus(true);
				setTimeout(() => {
					setErrorStatus(false);
				}, 5000);
			}
			if(data.user) {
				localStorage.setItem('initName', data.user.nome);
				localStorage.setItem('cat', data.user.categoria);
				localStorage.setItem('firstTime', false);
				set(true);
				navigate('/');
			}
		}
		catch (err) {
			console.log(err);
		}
	}
	
	const [pwType, setPwType] = useState(true);
	const [eye, setEye] = useState('M332,200H318.23V159.51c0-67.87-55.27-123-123.59-123.5H193C124.52,36.37,69.1,91.55,69.1,159.51V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200ZM124.46,159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62V200H124.46Z');
	const togglePassword = (e) => {
		e.cancelable && e.preventDefault();
		setPwType(!pwType);
		if(pwType) {
			setEye('M388.41,36h-1.66c-68.46.36-123.88,55.54-123.88,123.5V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200H318.23V159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62v41.17a27.68,27.68,0,0,0,55.36,0V159.51C512,91.64,456.73,36.53,388.41,36Z')
		} else {
			setEye('M332,200H318.23V159.51c0-67.87-55.27-123-123.59-123.5H193C124.52,36.37,69.1,91.55,69.1,159.51V200H55.33A55.31,55.31,0,0,0,0,255.23V420.81A55.31,55.31,0,0,0,55.33,476H332a55.32,55.32,0,0,0,55.34-55.19V255.23A55.32,55.32,0,0,0,332,200ZM124.46,159.51c0-37.91,31-68.62,69.2-68.62s69.21,30.71,69.21,68.62V200H124.46Z');
		}
	}

	return (
		<div className='main-div'>
			<Logo />
			<form onSubmit={handleSubmit} className='userSelect'>
				<div className='figure'>
					<a href='https://www.powershield.pt/' target='_blank' rel='noreferrer'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M256,0C114.6,0,0,114.6,0,256S114.6,512,256,512,512,397.4,512,256,397.4,0,256,0ZM48,256A206.37,206.37,0,0,1,69.1,165.37c1.13,2.45,2,5,3.48,7.29,11.72,17.39,28.38,42.07,35.67,52.77a114.4,114.4,0,0,0,18.06,20.74l.79.73a144.26,144.26,0,0,0,31.65,21.75c14,7.05,34.44,18.16,48.81,26.11a31.9,31.9,0,0,1,16.46,28v32a32,32,0,0,0,9.37,22.63c15,15,24.32,38.63,22.63,51.25v13.07c0,11.6,9.4,21,21,20.1a20.8,20.8,0,0,0,2.49-.15l1.56-.21A113.31,113.31,0,0,1,256,464C141.3,464,48,370.7,48,256ZM285.2,461.7a20.87,20.87,0,0,0,16.72-15.62L316,404.45c2-5.49,3.26-11.21,4.77-16.87A23.9,23.9,0,0,1,327,376.87c3.32-3.33,7.41-7.4,11.31-11.28a46.46,46.46,0,0,0,13.72-33,30,30,0,0,0-9-21.59l-13.71-13.64c-6-6-14.09-10.26-23.49-9.36H240c-9.41-4.71-21.48-32-32-32a67.88,67.88,0,0,1-30.3-7.2l-11.1-5.5a12,12,0,0,1,1.56-22l31.17-10.39A16,16,0,0,1,214.9,214l9.28,8.06a8,8,0,0,0,5.24,2h5.64a8,8,0,0,0,8-8,7.4,7.4,0,0,0-.9-3.6l-15.5-31.2a7.89,7.89,0,0,1,1.5-9.3l9.92-9.65A8,8,0,0,1,243.7,160h9a8,8,0,0,0,5.66-2.34l8-8a8,8,0,0,0,0-11.31l-4.69-4.69a8,8,0,0,1,0-11.31l15-15a16,16,0,0,0,0-22.63h0L252.3,60.29a12.37,12.37,0,0,0-9.55-3.61c-2.55.17-5.05.38-7.55.65A12.41,12.41,0,0,0,224,69.66a16.36,16.36,0,0,1-11.59,15.83,16,16,0,0,1-11.57-1.07,66.09,66.09,0,0,1-16-11.24l-9.38-8.93A206.67,206.67,0,0,1,256,48c82.61,0,153.9,48.53,187.5,118.5a76.06,76.06,0,0,0-21.77,6.7,24.13,24.13,0,0,0-9.24,8.15L392.9,210.73a24,24,0,0,0,0,26.62l18,27a24,24,0,0,0,10.54,8.78L442,283.23,460.57,293C444.7,380.6,373.9,449.2,285.2,461.7Z'/>
						</svg>
					</a>
					<a href='https://www.facebook.com/powershield.pt' target='_blank' rel='noreferrer'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M375,288l14.21-92.66H300.41V135.21c0-25.35,12.41-50.06,52.2-50.06H393V6.26S356.35,0,321.31,0c-73.16,0-121,44.38-121,124.72v70.62H119V288h81.32V512H300.41V288Z'/>
						</svg>
					</a>
					<a href='https://www.instagram.com/powershield.pt/' target='_blank' rel='noreferrer'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M510.2,150.44h0c-1.94-41-11.31-77.35-41.35-107.28S402.6,3.86,361.6,1.8C319.34-.6,192.66-.6,150.4,1.8,109.51,3.74,73.19,13.11,43.15,43S3.85,109.31,1.8,150.32c-2.4,42.27-2.4,169,0,211.24,1.94,41,11.31,77.35,41.35,107.28s66.25,39.3,107.25,41.36c42.26,2.4,168.94,2.4,211.2,0,41-1.94,77.33-11.31,107.25-41.36s39.29-66.26,41.35-107.28C512.6,319.29,512.6,192.71,510.2,150.44ZM455.48,406.79a86.36,86.36,0,0,1-48.63,48.63c-33.68,13.35-113.58,10.27-150.79,10.27s-117.23,3-150.79-10.27a86.3,86.3,0,0,1-48.63-48.63C43.28,373.12,46.36,293.21,46.36,256s-3-117.23,10.28-150.79a86.33,86.33,0,0,1,48.63-48.63c33.67-13.35,113.57-10.27,150.79-10.27s117.23-3,150.79,10.27a86.33,86.33,0,0,1,48.63,48.63c13.35,33.67,10.27,113.58,10.27,150.79S468.83,373.23,455.48,406.79Z M256,125A131,131,0,1,0,387,256,130.79,130.79,0,0,0,256,125Zm0,216a85,85,0,1,1,85-85A85.15,85.15,0,0,1,256,341Z'/>
							<circle cx='392' cy='120' r='30.5'/>
						</svg>
					</a>
					<a href='https://www.linkedin.com/company/powershieldpt/' target='_blank' rel='noreferrer'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M61.47,123.52C27.53,123.52,0,95.41,0,61.47a61.48,61.48,0,0,1,123,0C123,95.41,95.41,123.52,61.47,123.52Z M511.89,512H406V345.6c0-39.66-.8-90.51-55.19-90.51-55.19,0-63.65,43.08-63.65,87.65V512h-106V170.17H282.9V216.8h1.49c14.17-26.86,48.79-55.2,100.43-55.2C492.25,161.6,512,232.34,512,324.23V512Z'/>
							<rect x='8.46' y='170.17' width='106.15' height='341.83'/>
						</svg>
					</a>
				</div>
				<h1>
					<span>Portal</span>
					<br />
					<span>do </span>
					<span>Colaborador</span>
				</h1>

				{/* EMAIL */}
				<div className='section'>
					<label htmlFor='formEmail'>Email</label>
					<div className='input-div'>
						<input
							ref={firstRef}
							type='email'
							id='formEmail'
							name='formEmail'
							placeholder='exemplo@email.com'
							title=''
							required
							spellCheck='false'
							onKeyDown={handlePrevent}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
				</div>

				{/* PASSWORD */}
				<div className='section'>
					<label htmlFor='formPassword'>Senha</label>
					<div className='input-div'>
						<input
							type={pwType ? 'password' : 'text'}
							id='formPassword'
							name='formPassword'
							placeholder='senha'
							title=''
							required
							spellCheck='false'
							/* className='userSelect' */
							style={{userSelect: 'none'}}
							onKeyDown={handlePrevent}
							/* onInvalid={validation} */
							onChange={(e) => setPassword(e.target.value)}
						/>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
							className='eye'
							onClick={togglePassword}
						>
							<path d={eye} fill='var(--dark)' />
						</svg>
					</div>
				</div>

				{/* BUTTON */}
				<button type='submit' id='submit'>Iniciar sessão</button>

				{/* RECOVERY */}
				<h6 className='recovery'>
					<span>Recuperar/alterar a sua senha ? <br />Carregue </span>
					<span onClick={() => navigate('/reset')}>aqui</span>
				</h6>

				{/* First Time */}
				{/* <blockquote style={!firstTime ? {display: 'block'} : {display: 'none'}}>
					<PV />
				</blockquote> */}

				{/* ERROR */}
				<div className={errorStatus ? 'error status' : 'error'}>
					<p>{error}</p>
				</div>
			</form>
		</div>
	)
}


