import { useState } from 'react';

export default function Declaracao() {

  /* already clicked */
  const [clicked, setClicked] = useState(false);

  const handleDeclaracao = async (e) => {
    setClicked(true);
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    try {
      const res = await fetch(`${origin}/generatepdf`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-type': 'application/pdf' },
        responseType: 'blob',
      })
      const data = await res.blob();
      if(data) {
        const file = new Blob([data], {
          type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.download = '';
        link.target = '_blank';
        link.id = 'test';
        link.href = fileURL;
        /* window.open(fileURL); */
        link.click();
        setClicked(false);
      }
    } catch (error) {
      console.log(error);
    }
	}  

  return (
    <article
      className='declaracao relativeSVG cursor-pointer'
      onClick={clicked ? null : handleDeclaracao}
    >
      <h3>Declaração Contratual</h3>
      
      <p className="declaracao-long"><i>"A empresa <strong>POWERSHIELD - Segurança Privada, S.A.</strong>, com sede na Rua José Luís Monteiro, nº11D — 1950-162 Lisboa, Contribuinte nº ...</i></p>
      <p className="declaracao-short gap-zero"><i>"A empresa <strong>POWERSHIELD - Segurança Privada, S.A.</strong>, com sede na...</i></p>
      <span>
        <p>descarregar</p>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <path d='M447.62,458H64.38C49.26,458,37,469.88,37,484.53A27.19,27.19,0,0,0,64.38,512H447.62c15.12,0,27.38-11.88,27.38-26.53S462.8,458,447.62,458Z M382.5,200.69l-99,105.09V27.53a27.47,27.47,0,1,0-54.94-.09V305.69l-99.06-105a27.43,27.43,0,1,0-40,37.54L236,393.7c10.37,11.07,29.63,11.07,40,0L422.53,238.23a27.36,27.36,0,0,0-1.18-38.75C410.31,189,392.91,188.59,382.5,200.69Z'/>
        </svg>
      </span>
      <svg id='PDFsvg' className='absoluteSVG' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path d='M499.61,486c-9.7-9.72-19.33-19.51-29.1-29.16-15.37-15.19-29.8-31.41-47.72-43.82-20.34-14.1-42.25-24.82-66.19-31.19-10.13-2.69-19.36.48-24.32,8-8.72,13.22-2.95,28.93,12.85,33.64C367.07,430,387.4,439.58,405.31,454c14.31,11.5,26.28,25.46,39.37,38.23q10,9.78,19.8,19.78H512V498.42Z M113.1,397.56c2.41-2.1,6.71-5.72,10.89-9.46,30.27-27.15,65.1-45.9,104.1-57a273.77,273.77,0,0,1,60.8-9.74c16.63-.88,26.87-17,19.65-30.83-5.07-9.73-13.54-13.2-24.43-12.46C198.63,283.87,126.84,318,69.22,381.88A309.39,309.39,0,0,0,0,512H44.89C57.35,469.86,80.13,432,113.1,397.56Z M364.28,489.49A117.57,117.57,0,0,0,210.48,512H390.25C382.42,503.67,374.07,495.84,364.28,489.49Z M432,307.4a284.31,284.31,0,0,0-63.52-21.86A21.29,21.29,0,0,0,344,298.12c-3.95,9-1.13,19.25,7,25.74,3.54,2.82,7.79,3.78,12,4.84,47.83,12,89.55,34.85,124.44,70.06q12.25,12.39,24.58,24.72V362.25A303,303,0,0,0,432,307.4Z M181.86,465.13c25.75-24.62,56.36-40.05,91.72-45.57,14.32-2.23,22.72-12.78,20.58-25.43s-12.9-19.63-27-17.42c-45.57,7.1-84.5,27.46-119.09,61a216.57,216.57,0,0,0-21.56,27c-9.71,14.73-18,30.21-22.85,47.22h46A139,139,0,0,1,181.86,465.13Z' fill='var(--red)'/>
      </svg>
      {/* loader */}
        <blockquote
          className={clicked ? 'loader' : 'loader pointer-events fade'} 
        ></blockquote>
    </article>
  )
}
