import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Login.css'
import Logo from '../Logo'

export default function Recover() {

	const navigate = useNavigate();

	// focus on load
	const firstRef = useRef(null)
	useEffect(() => {
		firstRef.current.focus();
	}, []);

	// prevent spaces
	const handlePrevent = (e) => {
		if (e.keyCode === 32) {
			e.preventDefault();
			return false;
		};
	};

	// invalid event
	/* const [userIsInvalid, setUserIsInvalid] = useState(false);
	const validation = (e) => {
		e.preventDefault();
		if(e.currentTarget.type === 'email' && !e.currentTarget.validity.valid) {
			setUserIsInvalid(true);
			setTimeout(() => {
				setUserIsInvalid(false);
			}, 1125);
		};
	}; */

	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');
	const [errorStatus, setErrorStatus] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(!success) {
			document.documentElement.setAttribute('id', 'loader');
			
			let origin;
			if(window.location.host === 'localhost:3000') {
				origin = `${window.location.protocol}//${window.location.hostname}:80`;
			} else {
				origin = window.location.origin;
			}
			console.log(origin);
			/* fetch */
			try {
				const res = await fetch(`${origin}/checkemail`, {
					method: 'POST',
					body: JSON.stringify({ email }),
					headers: { 'Content-type': 'application/json' },
				})
				const data = await res.json();
				console.log(data);
				if(data.errors) {
					document.documentElement.removeAttribute('id', 'loader');
					setError(data.errors.login);
					setErrorStatus(true);
					setTimeout(() => {
						setErrorStatus(false);
					}, 5000);
					/* if(clickedState) {
						setClickedAgain(true);
						setTimeout(() => {
							setClickedAgain(false);
						}, 625);
					}
					setClickedState(true); */
				}
				if(data.msg) {
					document.documentElement.removeAttribute('id', 'loader');
					setError(data.msg);
					setSuccess(true);
					setErrorStatus(true);
					setTimeout(() => {
						setErrorStatus(false);
					}, 5000);
					/* setClickedState(true); */
				}
			} catch (error) {
				console.log(error);
			}
		}
	}


	return (
		<div className='main-div'>
			<Logo />
			<form onSubmit={handleSubmit}>
				<h1>
					<span>Definir</span>
					<br />
					<span>a </span>
					<span>Senha</span>
				</h1>
				{/* EMAIL */}
				<div className='section'>
					<label htmlFor='formEmail2' style={{marginBottom: '.25em'}}>Insira o seu email <br /> associado ao seu <br /> contrato Powershield.</label>
					<div className='input-div'>
						<input
							ref={firstRef}
							type='email'
							id='formEmail2'
							name='formEmail2'
							placeholder='exemplo@email.com'
							title=''
							required
							spellCheck='false'
							onKeyDown={handlePrevent}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
				</div>

				{/* BUTTON */}
				<button type='submit' id='submit' style={success ? {cursor: 'not-allowed'} : {}}>Continuar</button>

				{/* RECOVERY */}
				<h6 className='recovery'>
					<span>Sabe a sua senha? <br />Iniciar sessão&nbsp;</span>
					<span onClick={() => navigate('/login')}>aqui</span>
				</h6>

				{/* ERROR */}
				<div className={errorStatus ? 'error status' : 'error'}>
					<p>{error}</p>
				</div>
			</form>
		</div>
	)
}
