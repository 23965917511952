import { useState } from 'react';

export default function Protocolos(props) {

  /* already clicked */
  const [clicked, setClicked] = useState(false);

  const handleDownload = (e) => {
    setClicked(true);
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
    fetch(`${origin}/anexos?id=${e.currentTarget.dataset.path}`, {
      method: 'GET',
      headers: { 'Content-type': 'application/pdf' },
      credentials: 'include',
      responseType: 'blob',
    })
    .then(res => res.blob())
    .then(response => {
      const file = new Blob([response], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement('a');
      link.download = '';
      link.target = '_blank';
      link.id = 'test';
      link.href = fileURL;
      console.log(link);
      link.click();
      /* window.open(fileURL); */
      setClicked(false);
    })
    .catch(error => {
      console.log(error);
    });
	}

  return (
    <article className='txt'>
      <h4>{props.protocolo}</h4>

      {props.protocolo === 'Repsol' ?
      <>
      <h6>Cartão <u style={{textDecoration: 'underline solid #f5a509', textUnderlineOffset: '3px', textDecorationThickness: 'var(--embr)'}}>SOLRED REPSOL MAIS</u></h6>
      <p>Com este cartão vai poder ter descontos nas estações de serviço REPSOL, tendo em conta o preço PVP:</p>
      <ul>
        <li>Desconto de 0,06€ p/ litro para combustíveis NEOTECH;</li>
        <li>Desconto de 0,05€ p/ litro para combustíveis simples.</li>
      </ul>
      <p className='p-footer'>(Caso ainda não possua este cartão, pedimos que nos informe de forma a podermos proceder à sua solicitação)</p>
      </>

      : props.protocolo === 'ABANCA' ? 
      <>
      <p>Abanca está a oferecer condições especiais a todos os colaboradores da Powershield:</p>
      <ul>
        <li>Conta à ordem com Pack de serviços - Beneficie do Pack Value sem precisar de produtos de investimento e sem qualquer custo de manutenção durante o primeiro ano;</li>
        <li>Crédito de Habitação;</li>
        <li>Crédito Pessoal;</li>
        <li>Fundos de investimento.</li>
      </ul>
      <button
        style={{background: '#5a85d7'}}
        data-path='abanca'
        onClick={handleDownload}
      >Anexo {props.protocolo}</button>
      <p className='p-footer'>(Para saber mais sobre as vantagens de cada um, consulte o documento em anexo)</p>
      </>

      : props.protocolo === 'Santander' ? 
      <>
      <p>Na Santander pode financiar os seus projectos:</p>
      <ul>
        <li>Redução de taxa e de comissões;</li>
        <li>Comparticipação dos custos nas soluções de transferência de crédito de habitação de outro banco.</li>
      </ul>
      <p>Existem também outras soluções para o dia a dia...</p>
      <button
        style={{background: '#ea1d25'}}
        data-path='santander'
        onClick={handleDownload}
      >Anexo {props.protocolo}</button>
      <p className='p-footer'>(Se gostava de saber mais pode contactar diretamente o banco, informação em anexo)</p>
      </>

      : props.protocolo === 'novobanco' ? 
      <>
      <p>Com o Novo Banco pode escolher entre diversas contas, cada uma com as suas vantagens exclusivas:</p>
      <ul>
        <li>Conta NB@Work;</li>
        <li>Conta NB100%@Work;</li>
        <li>Conta NB360º@Work.</li>
      </ul>
      <button
        className='darken25'
        style={{background: '#009e97', cursor: 'default'}}
        data-path='novobanco'
      >Anexo {props.protocolo}</button>
      <p className='p-footer'>(Conheça com mais detalhe as ofertas, consultando o documento em anexo)</p>
      </>

      : props.protocolo === 'Bankinter' ?
      <>
      <button
        style={{background: '#f76900'}}
        data-path='bankinter'
        onClick={handleDownload}
      >Anexo {props.protocolo}</button>
      <p className='p-footer'>(Mais informações em anexo)</p>
      </>

      : <></>}
      {/* loader */}
      <blockquote
        className={clicked ? 'loader' : 'loader pointer-events fade'} 
      ></blockquote>
    </article>
  )
}
