import { useState, useEffect } from 'react';

export default function Rank(props) {

  return (
    <div className='rank'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
        className={props.lvl >= 1 || props.info >= 1 ? '' : 'darken25'}
      >
        <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
      </svg>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
        className={props.lvl >= 2 || props.info >= 2 ? '' : 'darken25'}
      >
        <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
      </svg>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
        className={props.lvl >= 3 || props.info >= 3 ? '' : 'darken25'}
      >
        <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
      </svg>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
        className={props.lvl >= 4 || props.info >= 4 ? '' : 'darken25'}
      >
        <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
      </svg>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'
        className={props.lvl >= 5 || props.info >= 5 ? '' : 'darken25'}
      >
        <path d='M346.21,153.21l139.32,20.62a31,31,0,0,1,24.92,21,31.32,31.32,0,0,1-7.66,31.9l-101,99.4L425.62,468.7a31.09,31.09,0,0,1-45.18,32.68L256,434.76,131.57,501.38A31,31,0,0,1,86.39,468.7l23.85-142.57-101-99.4a31.31,31.31,0,0,1-7.65-31.9,30.85,30.85,0,0,1,24.94-21l139.2-20.62,62.44-128.7a30.94,30.94,0,0,1,55.75,0Z' fill='var(--red)'/>
      </svg>
    </div>
  )
}
