import { useState, useEffect, useRef } from 'react'
import './Menu.css';
import './MenuPerfil.css';
import Plano from './articles/Plano';
import Declaracao from './articles/Declaracao';
import Cone from '../SVGS/Cone';

export default function MenuPerfil(props) {

  const [info, setInfo] = useState([]);
	const fetchInfo = async () => {
    let origin;
    if(window.location.host === 'localhost:3000') {
      origin = `${window.location.protocol}//${window.location.hostname}:80`;
    } else {
      origin = window.location.origin;
    }
    /* fetch */
		try {
			const res = await fetch(`${origin}/info`, {
				method: 'GET',
				credentials: 'include',
			})
			const data = await res.json();
			if(data) {
				setInfo([...data]);
			} else {
				console.log('err');
			}
		} catch (error) {
			console.log(error);
		}
		return
	}

  useEffect(() => {
    fetchInfo();
    // ref
    setPadding(window.screen.width - menuRef.current.getBoundingClientRect().right);
    setTop(menuRef.current.getBoundingClientRect().top);
  }, [])

  // menu max height
  const [padding, setPadding] = useState(0);
  const [top, setTop] = useState(0);
  const menuRef = useRef(null);

  const showInfo = () => {
    for (let i = 0; i < info.length; i++) {
      return (
        <>
          <li>
            <h5>Nome:</h5>
            <p>{info[i].NOME}</p>
          </li>
          <li>
            <h5>Data de Nascimento:</h5>
            <p>{info[i].DATA_NASCIMENTO}</p>
          </li>
          <li>
            <h5>Género:</h5>
            <div>
              <label htmlFor="feminino" style={{pointerEvents: 'none'}}>
                Feminino
                <input type="radio" id="feminino" checked={info[i].SEXO === 'F' ? true : false} readOnly />
                <span className='check'></span>
              </label>
              <label htmlFor="masculino" style={{pointerEvents: 'none'}}>
                Masculino
                <input type="radio" id="masculino" checked={info[i].SEXO === 'M' ? true : false} readOnly />
                <span className='check'></span>
              </label>
            </div>
          </li>
          <li>
            <h5>Email:</h5>
            <p>{info[i].E_MAIL}</p>
          </li>
          <li>
            <h5>Telemóvel:</h5>
            <p>{info[i].TELEMOVEL}</p>
          </li>
          <li>
            <h5>Morada:</h5>
            <p>{info[i].MORADA}</p>
          </li>
          <li>
            <h5>Código Postal:</h5>
            <p>{info[i].CODIGO_POSTAL}</p>
          </li>
          <li>
            <h5>Freguesia:</h5>
            <p>{info[i].FREGUESIA_MORADA}</p>
          </li>
          <li>
            <h5>Concelho:</h5>
            <p>{info[i].CONCELHO_MORADA}</p>
          </li>
          <li>
            <h5>Distrito:</h5>
            <p>{info[i].DISTRITO_MORADA}</p>
          </li>
          <li>
            <h5>Nacionalidade:</h5>
            <p>{info[i].NACIONALIDADE}</p>
          </li>
        </>
      )
    }
  }

  const showInfo2 = () => {
    for (let i = 0; i < info.length; i++) {
      return (
        <>
          <li>
            <h5>Estado civil:</h5>
            <p>{info[i].ESTADO_CIVIL}</p>
          </li>
          <li>
            <h5>Categoria:</h5>
            <p>{info[i].IRS_CATEGORIA}</p>
          </li>
          <li>
            <h5>Titulares:</h5>
            <p>{info[i].IRS_TITULARES}</p>
          </li>
          <li>
            <h5>Dependentes:</h5>
            <p>{info[i].IRS_DEPENDENTES}</p>
          </li>
        </>
      )
    }
  }

  return (
    <menu ref={menuRef} className='perfil' style={{height: `calc(100% - ${top}px + ${padding}px)`}}>

      {/* Plano */}
      {props.cat === 'Vigilante'
      ? <Plano details={props.info} cat={props.cat} />
      : null}

     {/* Info pessoal */}
      <article className='info'>
        <h3>Informação Pessoal</h3>
        <ul>
          {showInfo()}
        </ul>
      </article>

     {/* Info IRS */}
      <article className='info-c'>
        <h3>Informação Contratual</h3>
        <ul>
          {showInfo2()}
        </ul>
      </article>

      {/* Declaração */}
      <Declaracao />

      {/* CV download */}
      <article className='cv darken'>
        <h3>O meu CV</h3>
        <Cone />
      </article>
    </menu>
  )
}